import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion';
import clsx from 'clsx';
import Link from 'next/link';
import IconUp from '../../../icons/direction/chevron/down.svg';
import type { StoreMenuItem } from '../../../types.d/storeTypes';

import styles from './FooterNavigation.module.scss';

const MenuItem = (item: StoreMenuItem) => {
  return (
    <Link
      href={item.url ?? ''}
      className={clsx(
        'text-body-small',
        styles[`footerNavigation__${item.title.toLowerCase()}`]
      )}
      target={item.newTab ? '_blank' : undefined}
    >
      {item.title}
    </Link>
  );
};

export const FooterNavigation = ({
  footerMenu,
}: {
  footerMenu: StoreMenuItem[];
}) => {
  if (!footerMenu || footerMenu.length === 0) return null;

  return (
    <div className={clsx(styles.footerNavigation)}>
      <ul className={clsx(styles.footerNavigation__desktop)}>
        {footerMenu.map((menuItem) => {
          return (
            <li
              className={clsx(styles.footerNavigation__level1)}
              key={menuItem.id}
            >
              <h2
                className={clsx(
                  styles.footerNavigation__heading,
                  'text-body-2-bold'
                )}
              >
                {menuItem.title}
              </h2>
              <hr />
              <ul className={clsx(styles.footerNavigation__list)}>
                {((menuItem || {}).menu || []).map((item) => (
                  <li
                    key={item.id}
                    className={clsx(styles.footerNavigation__listItem)}
                  >
                    <MenuItem {...item} />
                  </li>
                ))}
              </ul>
            </li>
          );
        })}
      </ul>

      <Accordion className={clsx(styles.footerNavigation__mobile)} collapsible>
        {footerMenu.map((menuItem) => {
          return (
            <AccordionItem
              key={menuItem.id}
              className={clsx(
                styles.footerNavigation__level1,
                styles.footerNavigation__menuItemMobile
              )}
            >
              <div className={styles.footerNavigation__headingWrapper}>
                <AccordionButton
                  className={clsx(styles.footerNavigation__heading)}
                >
                  <p className={'text-body-2-bold'}>{menuItem.title}</p>
                  <IconUp className={styles.footerNavigation__headingArrow} />
                </AccordionButton>
              </div>

              <div className={clsx(styles.footerNavigation__list)}>
                {(menuItem.menu || []).map((item) => (
                  <AccordionPanel
                    key={item.id}
                    className={clsx(styles.footerNavigation__listItem)}
                  >
                    <MenuItem {...item} />
                  </AccordionPanel>
                ))}
              </div>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
};
