/* eslint-disable regexp/no-unused-capturing-group */
import { Trans, t } from '@lingui/macro';
import type { StoreLocale } from '@store-constants';
import { isChLocale as checkIsChLocale } from '@store-constants';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { type FocusEvent, useState, useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { StoreError } from '../../../types.d/storeTypes';
import { Button } from '../button/Button';
import styles from './NewsletterSignup.module.scss';

interface NewsletterFormInput {
  email: string;
  dateOfBirth?: string;
  source: 'Z_SHOPIFY_FORM' | 'Z_SHOPIFY_POPUP';
  customerType: 'subscriberCon' | 'subscriberPro';
}

export type NewsletterSignUpResponse = {
  errors?: StoreError[];
  success?: boolean;
};

export interface NewsletterSignupProps {
  deviceType: 'mobile' | 'desktop';
  showDobField?: boolean;
  onSubmit?: (
    payload: NewsletterFormInput
  ) => Promise<NewsletterSignUpResponse>;
}

export const NewsletterSignup = ({
  deviceType,
  showDobField,
  onSubmit,
}: NewsletterSignupProps) => {
  const router = useRouter();
  const locale = router.locale;
  const radioLacquerId = `radio-newsletter-${deviceType}-lacquer`;
  const radioLicensedId = `radio-newsletter-${deviceType}-licensed`;
  const isChLocale = checkIsChLocale(locale as StoreLocale);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateDateOfBirth = (date: any) => {
    if (!date) return true;
    const regex =
      locale === 'en-GB' || isChLocale
        ? /((0[1-9]|1\d|2\d|3[01])-(01|03|05|07|08|10|12)|(0[1-9]|1\d|2\d|30)-(04|06|09|11)|(0[1-9]|1\d|2\d)-02)/
        : /((01|03|05|07|08|10|12)-(0[1-9]|1\d|2\d|3[01])|(04|06|09|11)-(0[1-9]|1\d|2\d|30)|02-(0[1-9]|1\d|2\d))/;
    return regex.test(date) && date.length <= 5;
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm<NewsletterFormInput>();
  const dateOfBirth = watch('dateOfBirth');
  const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onFormSubmit: SubmitHandler<NewsletterFormInput> = async (data) => {
    setLoading(true);
    setSubmissionErrors([]);
    setSubmissionSuccess(false);

    if (!onSubmit) return;
    if (data.dateOfBirth) {
      data.dateOfBirth =
        locale === 'en-GB' || isChLocale
          ? `1900-${data.dateOfBirth.split('-').reverse().join('-')}`
          : `1900-${data.dateOfBirth}`;
    }

    const { success, errors } = await onSubmit(data);
    setLoading(false);
    if (errors?.length && !success) {
      const generalError = t({
        id: 'footer.newsletter.generalError',
        message: 'There was an error submitting the form',
      });
      setSubmissionErrors([generalError]);
      return;
    }
    reset();
    setSubmissionSuccess(true);
  };

  useEffect(() => {
    if (!validateDateOfBirth(dateOfBirth || '')) {
      setError('dateOfBirth', {
        type: 'validate',
        message: 'Invalid date format (MM-DD)',
      });
    } else {
      clearErrors('dateOfBirth');
    }
  }, [dateOfBirth, setError, clearErrors]);

  const dobField = (
    <div className="form-group__field">
      <input
        type="text"
        id="registerDateOfBirth"
        minLength={5}
        maxLength={5}
        placeholder={t({
          id: 'page.register.dob',
          message:
            locale === 'en-GB' || isChLocale
              ? 'Date of Birth (DD-MM)'
              : 'Date of Birth (MM-DD)',
        })}
        {...register('dateOfBirth', {
          onChange: (e) => {
            if (e.target.value.length === 3 && !e.target.value.includes('-')) {
              e.target.value = `${e.target.value.slice(
                0,
                2
              )}-${e.target.value.slice(2)}`;
            }
          },
          pattern: {
            value:
              locale === 'en-GB' || isChLocale
                ? /((0[1-9]|1\d|2\d|3[01])-(01|03|05|07|08|10|12)|(0[1-9]|1\d|2\d|30)-(04|06|09|11)|(0[1-9]|1\d|2\d)-02)/
                : /((01|03|05|07|08|10|12)-(0[1-9]|1\d|2\d|3[01])|(04|06|09|11)-(0[1-9]|1\d|2\d|30)|02-(0[1-9]|1\d|2\d))/,
            message: t({
              id: 'page.register.dobError',
              message: 'Invalid date or format',
            }),
          },
        })}
        onBlur={(event: FocusEvent<HTMLInputElement>) => {
          if (!event?.currentTarget?.value) {
            event.currentTarget.removeAttribute('data-valid');
            return;
          }

          event.currentTarget.setAttribute('data-valid', '');
        }}
        className={clsx(
          errors.dateOfBirth &&
            errors.dateOfBirth.type === 'validate' &&
            styles.newsletter__errors
        )}
      />
      <label
        className={clsx(
          styles.newsletter__input,
          errors.dateOfBirth &&
            errors.dateOfBirth.type === 'validate' &&
            styles.newsletter__label__error,
          'form-group__label text-body-2-desktop text-body-2-mobile'
        )}
        htmlFor={`newsletter_dob_${deviceType}`}
      >
        <Trans id="footer.newsletter.dob">Date of Birth (MM-DD)</Trans>
      </label>
    </div>
  );
  useEffect(() => {
    if (submissionSuccess) {
      const timer = setTimeout(() => setSubmissionSuccess(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [submissionSuccess]);

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className={styles.newsletter}
      data-test-id="newsletterSignup"
    >
      <fieldset disabled={loading}>
        <legend style={{ display: 'none' }}>
          <Trans id="footer.newsletter.legend">Footer Newsletter</Trans>
        </legend>
        <div className={styles.newsletter__header}>
          <h2
            className={clsx(
              styles.newsletter__title,
              'text-heading-h2-desktop',
              'text-heading-h2-mobile'
            )}
          >
            <Trans id="footer.newsletter.heading">{15}</Trans>
          </h2>
          <p
            className={clsx(
              styles.newsletter__message,
              'text-heading-h6-desktop',
              'text-heading-h6-mobile'
            )}
          >
            <Trans id="footer.newsletter.subheading">
              You'll be the first to know about new collections, exclusive
              events, and more from{' '}
              <a
                target="_blank"
                href="https://www.wellacompany.com/our-brands"
                rel="noreferrer"
                aria-label={t({
                  message: 'Wella Brands Url, opens a new tab',
                })}
              >
                Wella Brands
              </a>
              .
            </Trans>
          </p>
        </div>

        <input hidden {...register('source', { value: 'Z_SHOPIFY_FORM' })} />

        <div className={styles.newsletter__body}>
          <div className={styles.newsletter__inputContainer}>
            <div className="form-group">
              <div className="form-group__field">
                <input
                  id={`newsletter_email_${deviceType}`}
                  placeholder="Enter your email address"
                  type="email"
                  required
                  {...register('email', { required: true })}
                />
                <label
                  className={clsx(
                    styles.newsletter__input,
                    'form-group__label text-body-2-desktop text-body-2-mobile'
                  )}
                  htmlFor={`newsletter_email_${deviceType}`}
                >
                  <Trans id="footer.newsletter.email_label">
                    Enter your email address *
                  </Trans>
                </label>
              </div>
              {showDobField && dobField}
            </div>
          </div>
        </div>

        <div className={styles.newsletter__body}>
          <div
            className={styles.newsletter__radioContainer}
            role="radiogroup"
            aria-labelledby={`newsletter-radio-title-${deviceType}`}
          >
            <div
              id={`newsletter-radio-title-${deviceType}`}
              className="visually-hidden"
            >
              <Trans id="footer.newsletter.radio-group">Customer Type</Trans>
            </div>
            <div className="radio">
              <input
                id={radioLacquerId}
                className="radio__input"
                defaultChecked={true}
                value={'subscriberCon'}
                type="radio"
                tabIndex={0}
                {...register('customerType')}
              />
              <label
                className="radio__label text-body-small"
                htmlFor={radioLacquerId}
              >
                <Trans id="footer.newsletter.radioFanLabel">
                  I’m a lacquer Fan
                </Trans>
              </label>
            </div>
            <div className="radio">
              <input
                id={radioLicensedId}
                className="radio__input"
                value={'subscriberPro'}
                type="radio"
                tabIndex={0}
                {...register('customerType')}
              />
              <label
                className="radio__label text-body-small"
                htmlFor={radioLicensedId}
              >
                <Trans id="footer.newsletter.radioProLabel">
                  I’m a licensed Pro
                </Trans>
              </label>
            </div>
          </div>
        </div>

        <Button
          className={styles.newsletter__button}
          type={'submit'}
          modifiers={['black', 'mini']}
        >
          <Trans id="footer.newsletter.submit">sign up</Trans>
        </Button>

        {submissionErrors && submissionErrors.length > 0 && (
          <div className={styles.newsletter__errors}>
            {submissionErrors.map((error, index) => (
              <p
                key={`${error}-${index}`}
                className="text-body-2-desktop text-body-1-mobile"
              >
                {error}
              </p>
            ))}
          </div>
        )}

        {submissionSuccess && (
          <div
            className={clsx(
              styles.newsletter__success,
              'text-body-2-desktop text-body-1-mobile'
            )}
            role="alert"
            aria-live="polite"
          >
            <Trans id="footer.newsletter.successMsg">
              Thank you for subscribing!
            </Trans>
          </div>
        )}
      </fieldset>
    </form>
  );
};

NewsletterSignup.displayName = 'NewsletterSignup';
